//General Imports
import React, { useEffect, useState } from "react";
import { graphql, Script } from "gatsby";
import parse from "html-react-parser";
import _ from "lodash";
import { IntlProvider } from "react-intl";
import loadable from "@loadable/component";
import { getObject, isBrowser, isMobilePredicate } from "../../utils";

//Context
import LocalesContext from "../../context/LocalesContext";

//Layout
import Layout from "../../components/Layout";
import GoogleAds from "../../components/GoogleAds";
import SeoStructure from "../../components/SeoStructure";
import HeaderScripts from "../../components/HeadScripts";
import { TaboolaWebPushScript } from "../../components/vendor/TaboolaWebPushScript";

//Widgets
const Vidazoo = loadable(() => import("../../components/vendor/Vidazoo"));

// Flag to insert ads just for first image
let imageAdsInserted = false;

export function Head(props) {
  const data = props.data;
  let post;
  if (props.pageContext.customPostType === "custom_post_one") {
    post = data.customPostOne;
  } else {
    post = data.customPostTwo;
  }
  const canonicalSlug = `/${props.pageContext.originalSlug}`;
  const excerptWithoutTags = post.excerpt?.replace(/(<([^>]+)>)/gi, "");
  return (
    <>
      <HeaderScripts />
      <Script
        src="https://boot.pbstck.com/v1/tag/8c704138-0cf5-43be-9bf5-416e674a06d9"
        type="text/javascript"
      />
      <SeoStructure
        title={post.title}
        description={excerptWithoutTags ? excerptWithoutTags : ""}
        slug={`${props.pageContext.locale}${canonicalSlug}`}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            "@id": `${!post?.id ? "" : post?.id}`,
            datePublished: `${new Date(post?.date)}`,
            dateModified: `${new Date(post?.modified)}`,
            headline: `${!post?.title ? "" : post?.title}`,
            author: {
              "@type": "Person",
              name: `${
                !post?.author?.node?.name ? "" : post?.author?.node?.name
              }`,
            },
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `${!post?.slug ? "" : post?.slug}`,
            },
            publisher: {
              "@type": "Organization",
              name: `${
                !process.env.GATSBY_SITE_NAME
                  ? ""
                  : process.env.GATSBY_SITE_NAME
              }`,
              logo: {
                "@type": "ImageObject",
                url: "",
              },
            },
            image: {
              "@type": "ImageObject",
              url: `${
                !post?.featuredImage?.node?.link
                  ? ""
                  : post?.featuredImage?.node?.link
              }`,
              width: `${
                !post?.featuredImage?.node?.width
                  ? ""
                  : post?.featuredImage?.node?.width
              }`,
              height: `${
                !post?.featuredImage?.node?.height
                  ? ""
                  : post?.featuredImage?.node?.height
              }`,
            },
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: `${post.title}`,
              },
            ],
          })}
        </script>
      </SeoStructure>
    </>
  );
}

function CustomPost(props) {
  const data = props.data;
  const [consentTaken, setConsentTaken] = useState(false);

  let post;
  if (props.pageContext.customPostType === "custom_post_one") {
    post = data.customPostOne;
  } else {
    post = data.customPostTwo;
  }

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    imageAdsInserted = false;
    setIsMobile(isMobilePredicate());
  }, []);

  if (_.isEmpty(post)) return null;

  const options = {
    replace: (node) => {
      if (!isBrowser() || !isMobile || imageAdsInserted) {
        return;
      }

      if (!node.attribs) {
        return;
      }

      if (
        node.attribs &&
        node.attribs.class &&
        node.attribs.class.indexOf("gatsby-image-wrapper") !== -1
      ) {
        const picture = getObject(node.children, "name", "picture");
        const image = getObject(picture.children, "name", "img");
        const src = image.attribs["data-src"];
        const srcSet = image.attribs["data-srcset"];
        const sizes = image.attribs["sizes"];

        imageAdsInserted = true;

        return (
          <>
            <div className="code-block code-block--banner">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="LeaderBelowTitle" className="ad-container" />
            </div>
            <img src={src} sizes={sizes} srcSet={srcSet} alt="" />
            <div className="code-block code-block--banner">
              <div className="divider">
                <span> Advertisement</span>
              </div>
              <div id="MPUTopRight" className="ad-container" />
            </div>
          </>
        );
      }

      if (node.attribs && node.attribs.src) {
        imageAdsInserted = true;

        return (
          <>
            <div className="code-block code-block--banner">
              <div className="divider">
                <span> Advertisement</span>
              </div>
              <div id="LeaderBelowTitle" className="ad-container" />
            </div>
            <img src={node.attribs.src} alt="" />
            <div className="code-block code-block--banner">
              <div className="divider">
                <span> Advertisement</span>
              </div>
              <div id="MPUTopRight" className="ad-container" />
            </div>
          </>
        );
      }
      return;
    },
  };

  const changeConsentTaken = (value) => {
    setConsentTaken(value);
  };

  return (
    <>
      <GoogleAds
        type="standard"
        page={1}
        lang={props.pageContext.locale}
        changeConsentTaken={changeConsentTaken}
      />
      <article className="post">
        <div className="post-main">
          <header className="post-header">
            <h1 className="entry-title">{post.title}</h1>
          </header>

          <div className="post__video-player">
            <Vidazoo vidazooId="659d69a7ca46d867c34ed89b" />
          </div>

          {isBrowser() && !isMobile && (
            <div className="code-block code-block--banner">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="LeaderBelowTitle" className="ad-container" />
            </div>
          )}

          {post.content ? (
            <div className="post-content">{parse(post.content, options)}</div>
          ) : (
            <div
              className="post-content"
              dangerouslySetInnerHTML={{
                __html: post.content,
              }}
            />
          )}
        </div>
        <aside className="post-ads-sidebar col-1">
          <div className="post-ads-sidebar__inner">
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div
              id="LeftColumn"
              className="ad-container ad-container--sidebar"
            />
          </div>
          <div
            className="post-ads-sidebar__inner"
            style={{ marginTop: "10px" }}
          >
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div
              id="RightColumn"
              className="ad-container ad-container--sidebar"
            />
          </div>
        </aside>
      </article>
    </>
  );
}

const CustomPostSingle = (props) => {
  return (
    <>
      <TaboolaWebPushScript />
      <IntlProvider
        locale={props.pageContext.locale}
        messages={props.pageContext.messages}
      >
        <LocalesContext.Provider
          value={{
            slug: props.pageContext.originalSlug,
            avaiableLangs: [props.pageContext.locale],
            pageNumber: 0,
          }}
        >
          <Layout containerClasses="container--center" type="post">
            <CustomPost {...props} />
          </Layout>
        </LocalesContext.Provider>
      </IntlProvider>
    </>
  );
};

export default CustomPostSingle;

export const query = graphql`
  query ($slug: String!) {
    customPostOne: wpCustomPostTypeOne(slug: { eq: $slug }) {
      id
      title
      content
      excerpt
      featuredImage {
        node {
          link
          height
          width
        }
      }
    }

    customPostTwo: wpCustomPostTypeTwo(slug: { eq: $slug }) {
      id
      title
      content
      excerpt
      featuredImage {
        node {
          link
          height
          width
        }
      }
    }
  }
`;
